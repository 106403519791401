import React from 'react'
import PrivacyPolicies from './WebsitePolicyComponent'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'

const Index = (props) => {
  return (
    <>
      <Navbar />
      <PrivacyPolicies />
      <Footer />
    </>
  )
}

export default Index
