import {
  Box,
  Container,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import React from 'react'

const WebsitePolicyComponent = () => {
  return (
    <Container maxWidth="md">
      <Box
      // style={{ padding: '24px', marginTop: '24px', marginBottom: '24px' }}
      >
        {/* Usage Policy Section */}
        <Box my={4}>
          <Typography
            fontWeight="bold"
            variant="h5"
            component="h2"
            sx={{ fontWeight: 'bold' }}
            gutterBottom
          >
            WEBSITE USAGE POLICY
          </Typography>
          <Typography variant="body1" paragraph>
            This website is owned and operated by VIBRANTUM LABZ PVT LTD.
            Throughout the site, the terms "we", "us" and "our" refer to
            VIBRANTUM LABZ PVT LTD. By accessing or using our website, you agree
            to be bound by the terms and conditions set forth below. These terms
            and conditions may be updated or changed from time to time without
            notice to you. It is your responsibility to review this page
            periodically for any updates or changes.
          </Typography>
        </Box>
        <Divider />
        {/* Privacy Policy Section */}
        <Box my={4}>
          <Typography
            fontWeight="bold"
            variant="h5"
            component="h2"
            sx={{ fontWeight: 'bold' }}
            gutterBottom
          >
            PRIVACY POLICY
          </Typography>
          <Typography variant="body1" paragraph>
            VIBRANTUM LABZ PVT LTD, we respect and protect the privacy of our
            users. This Privacy Policy explains how we collect, use, and
            safeguard the information you provide to us through our website.
          </Typography>

          <Box mt={3}>
            <Typography variant="h6" component="h3" gutterBottom>
              1. Information Collection and Use
            </Typography>
            <Typography variant="body1" paragraph>
              As our website does not collect any personal information, the only
              data collected is essential usage data and anonymous analytics
              data to help us understand how our website is being used and to
              make improvements. None of your personal information is collected
              or stored in any way.
            </Typography>
          </Box>

          <Box mt={3}>
            <Typography variant="h6" component="h3" gutterBottom>
              2. Cookies
            </Typography>
            <Typography variant="body1" paragraph>
              We use cookies on our website to provide you with the best
              browsing experience. Cookies are small data files stored on your
              browser by our website. Only essential cookies are used, which are
              necessary for the basic functioning of the website. We also use
              analytics cookies, which help us to understand how users interact
              with our website, allowing us to make improvements.
            </Typography>
            <Typography variant="body1" paragraph>
              You have the option to choose the type of cookies you want to be
              stored on your device. You can manage your cookie preferences by
              accessing the cookie settings in your browser or by visiting our
              website's cookie settings page. Please note that by disabling
              certain cookies, some features of our website may not function
              properly.
            </Typography>
          </Box>

          <Box mt={3}>
            <Typography variant="h6" component="h3" gutterBottom>
              3. Third-Party Analytics
            </Typography>
            <Typography variant="body1" paragraph>
              We use third-party analytics providers to help us understand how
              our website is being used and to make improvements. These
              third-party providers may collect anonymous usage data, but they
              do not collect any personally identifiable information.
            </Typography>
          </Box>

          <Box mt={3}>
            <Typography variant="h6" component="h3" gutterBottom>
              4. Data Security
            </Typography>
            <Typography variant="body1" paragraph>
              The protection of your information is important to us. Although we
              do not collect any personally identifiable information through our
              website, we still take security measures to protect our user data.
              We use secure servers and implement technical and organizational
              measures, in compliance with GDPR, to ensure the security and
              confidentiality of our visitors' data.
            </Typography>
            <Typography variant="body1" paragraph>
              Please bear in mind that the transmission of information via the
              internet is never completely secure, and while we strive to
              protect your data, we cannot guarantee its absolute security. GDPR
              Compliance
            </Typography>
            <Typography variant="body1" paragraph>
              We are committed to complying with the General Data Protection
              Regulation (GDPR) in our data processing practices. Although we do
              not collect any personally identifiable information through our
              website, we still adhere to the principles of data protection,
              such as processing data in a transparent manner, ensuring its
              accuracy, and keeping it secure.
            </Typography>
          </Box>
        </Box>
        <Divider />
        {/* Terms & Conditions Section */}
        <Box my={4}>
          <Typography
            fontWeight="bold"
            variant="h5"
            component="h2"
            sx={{ fontWeight: 'bold' }}
            gutterBottom
          >
            TERMS AND CONDITIONS
          </Typography>

          <Box mt={3}>
            <Typography variant="h6" component="h3" gutterBottom>
              1. Intellectual Property Rights
            </Typography>
            <Typography variant="body1" paragraph>
              All content on this website, including but not limited to text,
              graphics, images, logos, and other material, is the exclusive
              property of VIBRANTUM LABZ PVT LTD or its licensors and is
              protected under applicable copyright, trademark, and other
              intellectual property laws. Unauthorized use, reproduction,
              distribution, or modification of any content, in whole or in part,
              is strictly prohibited without our express written permission.
            </Typography>
          </Box>

          <Box mt={3}>
            <Typography variant="h6" component="h3" gutterBottom>
              2. Website Use and Restrictions
            </Typography>
            <Typography variant="body1" paragraph>
              You are free to use our website for any lawful purpose. However,
              you are prohibited from:
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon style={{ minWidth: '30px' }}>
                  <FiberManualRecordIcon style={{ fontSize: 10 }} />
                </ListItemIcon>
                <ListItemText primary="Engaging in any activity that interferes with the proper working of our website or servers." />
              </ListItem>
              <ListItem>
                <ListItemIcon style={{ minWidth: '30px' }}>
                  <FiberManualRecordIcon style={{ fontSize: 10 }} />
                </ListItemIcon>
                <ListItemText primary="Copying, duplicating, or distributing any content without our written permission." />
              </ListItem>
              <ListItem>
                <ListItemIcon style={{ minWidth: '30px' }}>
                  <FiberManualRecordIcon style={{ fontSize: 10 }} />
                </ListItemIcon>
                <ListItemText primary="Accessing or using our website for any purpose that is unlawful or that violates these terms and conditions." />
              </ListItem>
              <ListItem>
                <ListItemIcon style={{ minWidth: '30px' }}>
                  <FiberManualRecordIcon style={{ fontSize: 10 }} />
                </ListItemIcon>
                <ListItemText primary="Using any automated data mining or extraction tools to gather, extract, or download any content from our website without our express written permission." />
              </ListItem>
            </List>
          </Box>

          <Box mt={3}>
            <Typography variant="h6" component="h3" gutterBottom>
              3. External Links
            </Typography>
            <Typography variant="body1" paragraph>
              Our website may contain links to external websites not owned or
              controlled by us. VIBRANTUM LABZ PVT LTD is not responsible for
              the content, policies, or practices of any linked websites. We do
              not endorse or assume any responsibility for any external websites
              or their content. Clicking on external links is at your own risk.
            </Typography>
          </Box>

          <Box mt={3}>
            <Typography variant="h6" component="h3" gutterBottom>
              4. Limitation of Liability
            </Typography>
            <Typography variant="body1" paragraph>
              To the maximum extent permitted by law, VIBRANTUM LABZ PVT LTD
              disclaims any liability for any damages, losses, or injuries of
              any kind, whether direct, indirect, incidental, consequential, or
              otherwise, arising out of or in connection with the use of our
              website or any content contained therein. Your use of our website
              is at your sole risk.
            </Typography>
          </Box>

          <Box mt={3}>
            <Typography variant="h6" component="h3" gutterBottom>
              5. Indemnification
            </Typography>
            <Typography variant="body1" paragraph>
              By using our website, you agree to indemnify, defend, and hold
              harmless VIBRANTUM LABZ PVT LTD, its affiliates, officers,
              directors, employees, agents, and licensors from and against any
              and all claims, losses, expenses, damages, and costs, including
              reasonable attorneys' fees, arising out of or in connection with
              your use of our website or any violation of these terms and
              conditions.
            </Typography>
          </Box>

          <Box mt={3}>
            <Typography variant="h6" component="h3" gutterBottom>
              6. Governing Law
            </Typography>
            <Typography variant="body1" paragraph>
              These terms and conditions and any dispute arising from or in
              connection with them will be governed by and construed in
              accordance with the laws of the jurisdiction where VIBRANTUM LABZ
              PVT LTD is registered, without regard to its conflict of law
              provisions.
            </Typography>
          </Box>

          <Box mt={3}>
            <Typography variant="h6" component="h3" gutterBottom>
              7. Changes to This Policy
            </Typography>
            <Typography variant="body1" paragraph>
              We reserve the right to modify these terms and conditions or our
              privacy policy at any time, without providing you with notice.
              Changes will be effective immediately upon their posting on our
              website. It is your responsibility to review these terms and
              conditions and the privacy policy periodically to stay informed
              about any updates or changes.
            </Typography>
          </Box>

          <Box mt={3}>
            <Typography variant="h6" component="h3" gutterBottom>
              8. Contact Us
            </Typography>
            <Typography variant="body1" paragraph>
              If you have any questions or concerns about these terms and
              conditions or our privacy policy, please contact us at
              <a
                href="mailto:care@casaretail.ai"
                target="_blank"
                rel="noopener noreferrer"
                // className={contactItem}
              >
                {' care@casaretail.ai '}
              </a>
              or by mail at:
            </Typography>
            <Typography variant="body1" style={{ marginLeft: '16px' }}>
              VIBRANTUM LABZ PVT LTD
              <br />
              Door 3, 1st Floor, Lalithambika Flats,
              <br />
              1, 79th St, 18th Ave, Ashok Nagar
              <br />
              Chennai 600083
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box mt={4} mb={2} textAlign="right">
          <Typography variant="caption" color="textSecondary">
            Last Updated: February 27, 2024
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}

export default WebsitePolicyComponent
